import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Container from '../components/layout/container';
import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import Content from '../components/ui/content';
import PageHeader from '../components/ui/page-header';
import { StaticImage } from 'gatsby-plugin-image';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <Container mdxType="Container">
        <h1>{`SAYIN TEKSTİL SANAYİ VE TİCARET LİMİTED ŞİRKETİ`}</h1>
        <p>{`Değerli Ziyaretçilerimiz…`}</p>
        <p>{`Bu aydınlatma metni 6698 sayılı Kişisel Verilen Korunması Kanunu’nun 10.maddesi ile Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul Ve Esaslar Hakkında Tebliğ kapsamında veri sorumlusu sıfatıyla Sayın Tekstil Sanayi Ve Ticaret Limited Şirketi tarafından hazırlanmıştır.`}</p>
        <p>{`Ziyaret etmiş olduğunuz web sitemizde gezinirken işlemiş olduğumuz kişisel verileriniz ile alakalı hukuki yükümlülüklerimizi yerine getirebilmek amacıyla bu aydınlatma metnini hazırladık. Sitemizi ziyaretiniz esnasında IP adres bilgileriniz ve log kayıtlarınız tarafımızca işlenmektedir.`}</p>
        <p>{`İşlenen bu kişisel verilerinizi ; Öncelikle mevzuattan kaynaklı yükümlülüklerimizin yerine getirilmesi amacıyla, Bilgi güvenliği süreçlerinin yürütülmesi, Erişim yetkilerinin yürütülmesi, İletişim faaliyetlerinin yürütülmesi, İş faaliyetlerinin yürütülmesi/denetimi, Yetkili kişi kurum ve kuruluşlara bilgi verilmesi, Yönetim faaliyetinin yürütülmesi amacıyla işlemekteyiz.
İşlenen bu kişisel verileriniz öncelikle talep edilmesi halinde ; Mevzuattan kaynaklanan hukuki yükümlülüklerimizi yerine getirmek amacıyla, Yetkili kişi, kurum ve kuruluşlar ile adli makamlarla paylaşılmaktadır. Bu verileriniz ayrıca web sitelerinin bulunduğu sunucuları yöneten servis sağlayıcılarına aktarılmaktadır.`}</p>
        <p>{`İşlemiş olduğumuz kişisel verilerinizi yurt dışına aktarmıyoruz.`}</p>
        <p>{`Belirtmiş olduğumuz kişisel verilerinizi ; Kişisel Verileriniz Koruma Kanunu m.5/2 uyarınca kanunlarda açıkça öngörülmesi ( Türk Ticaret Kanunu, Türk Ceza Kanunu, 5651 sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun Vs. İlgili kanun yönetmelik ve ikincil mevzuat uyarınca ) , veri sorumlusu olarak hukuki yükümlülüklerimizi yerine getirmek için iş bu kişisel verilerinizin işlenmesinin zorunlu olması dayanak olmak üzere işliyoruz.`}</p>
        <p>{`Web sitemizi ziyaret esnasında işlemiş olduğumuz kişisel verileriniz otomatik yollarla işlenmektedir.`}</p>
        <p>{`Kanunun ilgili kişinin haklarını düzenleyen 11. Maddesi kapsamında taleplerinizi, ‘ Veri Sorumlusuna Başvuru Usul Ve Esasları Hakkında Tebliğ’e göre Sayın Tekstil Sanayi Ve Ticaret Limited Şirketi’nin Saraylar Mah. 367 Sk. No:17 Merkezefendi/DENİZLİ adresine yazılı, kayıtlı elektronik posta adresi, güvenli elektronik imza, mobil imza veya Sayın Tekstil Sanayi Ve Ticaret Limited Şirketi’ne daha önce bildirilen ve sistemde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle `}<a parentName="p" {...{
            "href": "mailto:sayintextile@sayintextile.com"
          }}>{`sayintextile@sayintextile.com`}</a>{` e-posta adresine iletebilirsiniz.`}</p>
        <p>{`İlgili kişinin hakları`}</p>
        <p>{`MADDE 11- (1) Herkes, veri sorumlusuna başvurarak kendisiyle ilgili;`}</p>
        <p>{`a) Kişisel veri işlenip işlenmediğini öğrenme,`}</p>
        <p>{`b) Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,`}</p>
        <p>{`c) Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,`}</p>
        <p>{`ç) Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,`}</p>
        <p>{`d) Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,`}</p>
        <p>{`e) 7 nci maddede öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme,`}</p>
        <p>{`f) (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,`}</p>
        <p>{`g) İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,`}</p>
        <p>{`ğ) Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlindezararın giderilmesini talep etme, haklarına sahiptir.`}</p>
        <p>{`Veri Sorumlusuna Başvuru Usul Ve Esasları Hakkında Tebliğ’e göre Başvuruda Bulunması Zorunlu Unsurlar;`}</p>
        <p>{`Başvuru usulü`}</p>
        <p>{`MADDE 5 – (1) İlgili kişi, Kanunun 11 inci maddesinde belirtilen hakları kapsamında taleplerini, yazılı olarak veya kayıtlı elektronik posta (KEP) adresi, güvenli elektronik imza, mobil imza ya da ilgili kişi tarafından veri sorumlusuna daha önce bildirilen ve veri sorumlusunun sisteminde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle veya başvuru amacına yönelik geliştirilmiş bir yazılım ya da uygulama vasıtasıyla veri sorumlusuna iletir.`}</p>
        <p>{`(2) Başvuruda;`}</p>
        <p>{`a) Ad, soyad ve başvuru yazılı ise imza,`}</p>
        <p>{`b) Türkiye Cumhuriyeti vatandaşları için T.C. kimlik numarası, yabancılar için uyruğu, pasaport numarası veya varsa kimlik numarası,`}</p>
        <p>{`c) Tebligata esas yerleşim yeri veya iş yeri adresi,`}</p>
        <p>{`ç) Varsa bildirime esas elektronik posta adresi, telefon ve faks numarası,`}</p>
        <p>{`d) Talep konusu,
bulunması zorunludur.`}</p>
        <p>{`Sayın Tekstil Sanayi Ve Ticaret Limited Şirketi`}</p>
        <p><a parentName="p" {...{
            "href": "mailto:sayintextile@sayintextile.com"
          }}>{`sayintextile@sayintextile.com`}</a></p>
        <p>{`Büro: Saraylar Mah. 367 Sok. No: 17
20010 Denizli / Turkey
Tel: +90 258 263 05 60
Faks: +90 258 263 59 73`}</p>
      </Container>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      